import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";


function AbonosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_NOTAS_CARGO = process.env.REACT_APP_URL_NOTAS_CARGO;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteNombre, setSelectedClienteNombre] = useState("");
  const [selectedClienteTelefono, setSelectedClienteTelefono] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [alertasEdoCtaCliente, setAlertasEdoCtaCliente] = useState("No");

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [formaPago, setFormaPago] = useState("");
  const [referencia, setReferencia] = useState("");

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [concepto, setConcepto] = useState("");
  const [total, setTotal] = useState(0);
  const [importeAplicar, setImporteAplicar] = useState(0);
  const [saldoAplicar, setSaldoAplicar] = useState(0);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());
  const [moneda, setMoneda] = useState("");

  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsNC, setInputFieldsNC] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    

    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

  if(selectedCliente != ""){
        
    axios
      .get(`${URL_SURTIDOS}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos 
        .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))         
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.total_general,
              saldo: a.saldo,
              nota: a.idSurtido,
              importe: 0,
              clientes: a.clientes[0]._id,
              colaboradores: a.clientes[0].vendedor[0]._id,
              observaciones: "NA",
              sucursal: a.pedidos[0].sucursales[0].alias,
              movimiento: "Nota",
              saldoFinal: a.saldo
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_NOTAS_CARGO}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosNC = response.data;
        let arrayTablaNC = allCargosNC
        .sort((a, b) => (a.fecha > b.fecha ? 1 : -1)) 
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.importe,
              saldo: a.saldo,
              nota: a.idNotaCargo,
              importe: 0,
              clientes: a.clientes[0]._id,
              colaboradores: a.clientes[0].vendedor[0]._id,
              observaciones: "NA",
              sucursal: a.sucursales[0].alias,
              movimiento: "Nota de Cargo",
              saldoFinal: a.saldo
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinalNC = Object.values(arrayTablaNC);
        setInputFieldsNC(dataFinalNC);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [selectedCliente]);

  const handleChangeInputImporte = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo || value == null) {
          i[name] = value;
          i.saldoFinal = i.saldo - value
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputImporteNC = (value, name, id) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo || value == null) {
          i[name] = value;
          i.saldoFinal = i.saldo - value
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let TEfe = inputFields.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);

    let TEfeNC = inputFieldsNC.map((c) => parseFloat(c.importe));
    let TENC = TEfeNC.reduce((t, total, index) => t + total, 0);

    let TF = parseFloat(TE).toFixed(2)
    let TFNC = parseFloat(TENC).toFixed(2)

    let sum = (parseFloat(TF) + parseFloat(TFNC)).toFixed(2)

    setTotal(sum);

    setSaldoAplicar((importeAplicar - sum).toFixed(2));
  }

  async function savePago() {
    setValidaBoton(false);
    const red = parseFloat(importeAplicar).toFixed(2);

    if (!value || !concepto || !formaPago || !referencia || !selectedBanco) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Debes completar todo el formulario",
        });
        return;
    }

    if (red != total) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Todavía te queda saldo por aplicar",
        });
        return;
    }

    const confirm = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Se registrará el abono",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, Enviar!",
    });

    if (!confirm.isConfirmed) return;

    setValidaBoton(false);
    toggleProgreso();

    try {
        const requests = [
            ...inputFields.map(async (a) => {
                if (a.importe > 0) {
                    const response = await axios.post(
                        URL_ABONOS,
                        {
                            fecha,
                            importe: parseFloat(a.importe),
                            clientes: selectedCliente,
                            colaboradores: a.colaboradores,
                            surtidos: a.id,
                            formaPago,
                            referencia,
                            concepto,
                            bancos: selectedBanco,
                            comisionPagada: "No",
                            observaciones: a.observaciones,
                            codigoAgrupado,
                            editado: "No",
                        },
                        {
                            headers: {
                                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                            },
                        }
                    );

                    await Promise.all([
                        axios.patch(
                            `${URL_SURTIDOS}/${a.id}`,
                            { saldo: a.saldoFinal },
                            {
                                headers: {
                                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                                },
                            }
                        ),
                        axios.post(
                            `${URL_MOVIMIENTOS_BANCOS}`,
                            {
                                fecha,
                                importe: a.importe,
                                tipo: "Ingreso",
                                bancos: selectedBanco,
                                abonos: response.data._id,
                                moneda,
                                tipoCambio: 1,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                                },
                            }
                        ),
                    ]);
                }
            }),

            ...inputFieldsNC.map(async (a) => {
                if (a.importe > 0) {
                    const response = await axios.post(
                        URL_ABONOS,
                        {
                            fecha,
                            importe: a.importe,
                            clientes: selectedCliente,
                            colaboradores: a.colaboradores,
                            notasCargo: a.id,
                            formaPago,
                            referencia,
                            concepto,
                            bancos: selectedBanco,
                            comisionPagada: "No",
                            observaciones: a.observaciones,
                            codigoAgrupado,
                            editado: "No",
                        },
                        {
                            headers: {
                                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                            },
                        }
                    );

                    await Promise.all([
                        axios.patch(
                            `${URL_NOTAS_CARGO}AplicarAbono/${a.id}`,
                            { saldo: a.saldoFinal },
                            {
                                headers: {
                                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                                },
                            }
                        ),
                        axios.post(
                            `${URL_MOVIMIENTOS_BANCOS}`,
                            {
                                fecha,
                                importe: a.importe,
                                tipo: "Ingreso",
                                bancos: selectedBanco,
                                abonos: response.data._id,
                                moneda,
                                tipoCambio: 1,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                                },
                            }
                        ),
                    ]);
                }
            }),
        ];

        await Promise.all(requests);

        if (alertasEdoCtaCliente === "Si") PDFOC();

        Swal.fire("Good job!", "Creado con éxito", "success");
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    } catch (error) {
        setModalProgreso(false);
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo salió mal!",
            footer: error.response?.data?.message || "",
        });
        console.error(error);
    }
}


  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleChangeInputNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
  };

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function IA(e) {
    setImporteAplicar(e);

    let TEfe = inputFields.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);

    let TEfeNC = inputFieldsNC.map((c) => parseFloat(c.importe));
    let TENC = TEfeNC.reduce((t, total, index) => t + total, 0);

    let TF = parseFloat(TE).toFixed(2)
    let TFNC = parseFloat(TENC).toFixed(2)

    let sum = (parseFloat(TF) + parseFloat(TFNC)).toFixed(2)

    setTotal(sum);

    setSaldoAplicar((e - sum).toFixed(2));
  }

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });
  }

 async function PDFOC() {
    await axios
    .get(`${URL_ESTADO_CUENTA}Agrupado/${selectedCliente}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let movimientos = response.data;

    let saldoParcial = 0;
    let totalCargos = 0;
    let totalAbonos = 0;

    const data = movimientos.map((a) => {
        totalCargos = totalCargos + a.cargo;
        totalAbonos = totalAbonos + a.abono;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        return [
          a.fecha,
          a.sucursal,
          a.movimiento,
          a.numero,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${selectedClienteNombre}`, 15, 37);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Sucursal",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargos),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonos),
        ],
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    var attW = doc.output("arraybuffer");
   

    let params = new URLSearchParams({
      number: selectedClienteTelefono,
      message:`Hola ${selectedClienteNombre}, Aquí te enviamos tu estado de cuenta actualizado`,
      fileName:`EstadoCuenta.pdf`,
      mimetype: "application/pdf",
    })
    axios
    .post(
      `${URL_WHATSAPP}SendPDFFront?${params.toString()}`,
      attW,
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
          "Content-Type": "application/octet-stream",
        },
      }
    )

  })
  .catch((err) => {
    console.log(err);
  });

  }

  const handleSearchClientes = async (searchText) => {
    try {
      const response = await axios.get(`${URL_CLIENTES}Search/${searchText}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("app_token")}`, // Agregar el token al header
        },
      });
      // Verificar que response.data sea un arreglo
      setClientes(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error buscando clientes", error);
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosCreate ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onInputChange={(event, newInputValue) => {
                      handleSearchClientes(newInputValue);  // Hacer una nueva búsqueda en cada cambio
                    }}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                        setSelectedClienteNombre(selectedCliente.nombre_comercial);
                        setSelectedClienteTelefono(selectedCliente.telefono);
                        setAlertasEdoCtaCliente(selectedCliente.alertasEdoCta);
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Escribe las primeras letras"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={concepto}
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Abono">Abono</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label className="mr-sm-2">Forma de Pago</Label>
                  <Input
                    type="select"
                    value={formaPago}
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="">Selecciona una Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Transfer">Transfer</option>
                    <option value="TDC">TDC</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      jalaBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={1}>
                  <h4 align="right">
                    Total Pago{"  "}
                    <Badge
                      id="Total"
                      color="danger"
                      className="BadgeSize badge-pill"
                    >
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={2}>
                  <Label>Importe Por Aplicar</Label>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    prefix="$"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={importeAplicar}
                    className={`form-control`}
                    onValueChange={(value, name) => IA(value)}
                  />
                </Col>
                <Col md={2}>
                  <Label>Saldo Por Aplicar</Label>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    prefix="$"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={saldoAplicar}
                    className={`form-control`}
                    disabled
                  />
                </Col>

                <Col md={2}>
                  <Label className="mr-sm-2">Referencia</Label>
                  <Input
                    type="text"
                    value={referencia}
                    onChange={(e) => {
                      setReferencia(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                {/* Tabla Cargos */}
                <br />
                <br />
                <Table striped borderless>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Movimiento</th>
                      <th>Nota</th>
                      <th>Sucursal</th>
                      <th>Total</th>
                      <th>Abonos</th>
                      <th>Saldo</th>
                      <th>Importe</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputFields.map((a) => (
                      // <div key={a.id}>
                      <tr>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>{a.sucursal}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general - a.saldo
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                        </td>
                        <td>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="importe"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.importe}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputImporte(value, name, a.id)
                            }
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="observaciones"
                            value={a.observaciones}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInput(a.id, e);
                            }}
                          />
                        </td>
                      </tr>
                      // </div>
                    ))}

                    {inputFieldsNC.map((a) => (
                      // <div key={a.id}>
                      <tr>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>{a.sucursal}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general - a.saldo
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                        </td>
                        <td>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="importe"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.importe}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputImporteNC(value, name, a.id)
                            }
                          />
                        </td>
                        <td>
                          <Input
                            bsSize="sm"
                            type="text"
                            name="observaciones"
                            value={a.observaciones}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputNC(a.id, e);
                            }}
                          />
                        </td>
                      </tr>
                      // </div>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        Total
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  <br />
                </Table>

                {/* Termina tabla cargos */}
              </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/ListadoAbonos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default AbonosCreate;
